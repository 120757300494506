<template>
  <div class="survey-page">
    <survey-form :surveySlug="surveySlug" :parts="parts" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import SurveyForm from '@/components/SurveyForm.vue'

export default {
  name: 'Survey',
  components: {
    SurveyForm
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()

    const surveySlug = route.params.surveySlug

    if (['general'].indexOf(surveySlug) < 0) {
      console.log('Invalid survey slug, redirecting to /surveys/general/')
      router.push({
        path: '/surveys/general/'
      })
    }

    const parts = [
      {
        title: i18n.t('loveGodSurveyTitle'),
        subTitle: i18n.t('loveGodSurveyCaption'),
        min: Number(0),
        max: Number(5),
        small: false,
        messages: [
          {
            id: 0,
            text: 'I am daily walking in the power and under the control of the Holy Spirit',
            value: 0,
            feedback: {
              youtubeId: 'oNNZO9i1Gjc',
              youtubeName: 'BibleProject - Holy Spirit',
              watchExtra: [],
              readExtra: []
            }
          },
          {
            id: 1,
            text: 'I am daily practicing personal holiness',
            value: 0,
            feedback: {
              startBook: 75,
              endBook: 82,
              realStartBook: 68,
              realEndBook: 74,
              watchExtra: [],
              readExtra: [
                {
                  name: 'Be Holy as I am Holy',
                  url: 'https://www.gotquestions.org/be-holy-for-I-am-holy.html'
                }
              ]
            }
          },
          {
            id: 2,
            text: 'I am regularly giving financially to God through the church',
            value: 0,
            feedback: {
              url: 'https://trbc.org/give/',
              watchExtra: [],
              readExtra: []
            }
          },
          {
            id: 3,
            text: 'I am worshipping God by weekly participating in Sunday services',
            value: 0,
            feedback: {
              startBook: 39,
              endBook: 48,
              realStartBook: 34,
              realEndBook: 43,
              watchExtra: [],
              readExtra: []
            }
          },
          {
            id: 4,
            text: 'I am daily talking with and listening to God in prayer',
            value: 0,
            feedback: {
              startBook: 63,
              endBook: 74,
              realStartBook: 56,
              realEndBook: 67,
              watchExtra: [
                {
                  name: 'Does God Speak Audibly Today?',
                  url: 'https://www.youtube.com/watch?v=Ubd8QwUo33w&t=82s'
                },
                {
                  name: 'Bible Project - "Listen"',
                  url: 'https://www.youtube.com/watch?v=6KQLOuIKaRA'
                }
              ],
              readExtra: []
            }
          },
          {
            id: 5,
            text: 'I am daily reading, studying, meditating on Scripture',
            value: 0,
            feedback: {
              startBook: 55,
              endBook: 62,
              realStartBook: 48,
              realEndBook: 55,
              watchExtra: [],
              readExtra: [
                {
                  name: 'Why a child of God should study the Word of God',
                  url: 'https://digitalcommons.liberty.edu/study_the_word/1/'
                }
              ]
            }
          },
          {
            id: 6,
            text: 'I am daily obeying and practicing Scripture',
            value: 0,
            feedback: {
              startBook: 125,
              endBook: 128,
              realStartBook: 115,
              realEndBook: 118,
              watchExtra: [],
              readExtra: [
                {
                  name: 'What does the Bible say about Obedience?',
                  url: 'https://www.gotquestions.org/Bible-obedience.html'
                }
              ]
            }
          }
        ]
      },
      {
        title: i18n.t('lovePeopleSurveyTitle'),
        subTitle: i18n.t('lovePeopleSurveyCaption'),
        min: Number(0),
        max: Number(5),
        small: false,
        messages: [
          {
            id: 0,
            text: 'I am following Jesus with a specific group of people',
            value: 0,
            feedback: {
              startBook: 83,
              endBook: 93,
              realStartBook: 76,
              realEndBook: 86,
              watchExtra: [],
              readExtra: []
            }
          },
          {
            id: 1,
            text: 'I know and am using my gifts to serve people in church and community',
            value: 0,
            feedback: {
              startBook: 95,
              endBook: 104,
              realStartBook: 88,
              realEndBook: 97,
              watchExtra: [],
              readExtra: [
                {
                  name: 'How do I identify my Spiritual Gifts',
                  url: 'https://www.gotquestions.org/spiritual-gift.html'
                }
              ]
            }
          },
          {
            id: 2,
            text: 'I am actively loving my family and working to help them follow Jesus',
            value: 0,
            feedback: {
              url: 'https://trbc.org/parents/',
              watchExtra: [],
              readExtra: [
                {
                  name: 'How to witness to friends & family',
                  url: 'https://carm.org/evangelism/how-to-witness-to-friends-and-family/?highlight=family'
                }
              ]
            }
          },
          {
            id: 3,
            text: 'I am praying for and building relationships with lost people',
            value: 0,
            feedback: {
              startBook: 105,
              endBook: 113,
              realStartBook: 98,
              realEndBook: 106,
              watchExtra: [
                {
                  name: 'Pray for Lost People',
                  url: 'https://www.youtube.com/watch?v=i83wwpjf5Ts'
                }
              ],
              readExtra: []
            }
          },
          {
            id: 4,
            text: 'I am inviting people to hear the Gospel through church ministries',
            value: 0,
            feedback: {
              startBook: 105,
              endBook: 113,
              realStartBook: 98,
              realEndBook: 106,
              watchExtra: [
                {
                  name: '5 Ways to Invite Someone to Church',
                  url: 'https://www.youtube.com/watch?v=ewAGtaiaNz8'
                }
              ],
              readExtra: []
            }
          },
          {
            id: 5,
            text: 'I am sharing my faith story verbally with lost people',
            value: 0,
            feedback: {
              startBook: 13,
              endBook: 38,
              realStartBook: 8,
              realEndBook: 33,
              watchExtra: [],
              readExtra: [
                {
                  name: 'How do I share the Gospel with a “Fill in the Blank”',
                  url: 'https://www.gotquestions.org/share-the-gospel.html'
                }
              ]
            }
          },
          {
            id: 6,
            text: 'I am actively working to make new disciples',
            value: 0,
            feedback: {
              url: 'https://trbc.org/start',
              startBook: 105,
              endBook: 113,
              realStartBook: 98,
              realEndBook: 106,
              watchExtra: [
                {
                  name: 'Making Disciples, Not just Converts',
                  url: 'https://www.youtube.com/watch?v=S8EgHAsZHd8'
                }
              ],
              readExtra: []
            }
          }
        ]
      },
      {
        title: i18n.t('doctrineSurveyTitle'),
        subTitle: i18n.t('doctrineSurveyCaption'),
        min: Number(0),
        max: Number(5),
        small: true,
        messages: [
          {
            id: 0,
            text: 'God the Father / Trinity',
            value: 0,
            feedback: {
              youtubeId: 'eAvYmE2YYIU',
              youtubeName: 'BibleProject - God',
              startBook: 130,
              endBook: 134,
              realStartBook: 120,
              realEndBook: 124,
              watchExtra: [
                {
                  name: 'What does the Bible Teach about the Trinity?',
                  url: 'https://www.youtube.com/watch?v=yj0mE78SuWA'
                }
              ],
              readExtra: []
            }
          },
          {
            id: 1,
            text: 'God the Son, Jesus Christ',
            value: 0,
            feedback: {
              youtubeId: 'z6cWEcqxhlI',
              youtubeName: 'BibleProject - Son of Man',
              startBook: 134,
              endBook: 135,
              realStartBook: 124,
              realEndBook: 125,
              watchExtra: [],
              readExtra: [
                {
                  name: 'Jesus: God & Man',
                  url: 'https://carm.org/doctrine-and-theology/jesus-two-natures-god-and-man/'
                }
              ]
            }
          },
          {
            id: 2,
            text: 'God the Holy Spirit',
            value: 0,
            feedback: {
              youtubeId: 'oNNZO9i1Gjc',
              youtubeName: 'BibleProject - Holy Spirit',
              startBook: 135,
              endBook: 137,
              realStartBook: 125,
              realEndBook: 127,
              watchExtra: [],
              readExtra: [
                {
                  name: 'Who or What is the Holy Spirit? ',
                  url: 'https://carm.org/doctrine-and-theology/who-or-what-is-the-holy-spirit/'
                }
              ]
            }
          },
          {
            id: 3,
            text: "God's Word, the Bible",
            value: 0,
            feedback: {
              youtubeId: 'ak06MSETeo4',
              youtubeName: 'BibleProject - What is the Bible?',
              startBook: 138,
              endBook: 142,
              realStartBook: 128,
              realEndBook: 132,
              watchExtra: [
                {
                  name: "Is the Bible truly God's Word?",
                  url: 'https://www.youtube.com/watch?v=BocabFPaKH0'
                }
              ],
              readExtra: []
            }
          },
          {
            id: 4,
            text: 'Humanity, Men / Women',
            value: 0,
            feedback: {
              youtubeId: 'takEeHtRrMw',
              youtubeName: 'BibleProject - The New Humanity',
              startBook: 143,
              endBook: 145,
              realStartBook: 133,
              realEndBook: 135,
              watchExtra: [
                {
                  name: 'The Doctrine of Humanity',
                  url: 'https://www.youtube.com/watch?v=mC8tKu1wRgk'
                }
              ],
              readExtra: [
                {
                  name: 'What is Man?',
                  url: 'https://www.gotquestions.org/what-is-man.html'
                }
              ]
            }
          },
          {
            id: 5,
            text: 'Sin / Salvation',
            value: 0,
            feedback: {
              youtubeId: 'G_OlRWGLdnw',
              youtubeName: 'BibleProject - Sacrifice and Atonement',
              startBook: 144,
              endBook: 149,
              realStartBook: 134,
              realEndBook: 139,
              watchExtra: [],
              readExtra: []
            }
          },
          {
            id: 6,
            text: 'Spirit World: Angels, Demons, Satan',
            value: 0,
            feedback: {
              youtubeId: 'CamYtVpoTNk',
              youtubeName: 'BibleProject - The Satan and Demons',
              startBook: 155,
              endBook: 156,
              realStartBook: 145,
              realEndBook: 146,
              watchExtra: [
                {
                  name: 'Intro to Spiritual Beings',
                  url: 'https://www.youtube.com/watch?v=cBxOZqtGTXE'
                }
              ],
              readExtra: [
                {
                  name: 'What does the Bible say about Demons?',
                  url: 'https://www.gotquestions.org/demons-Bible.html'
                },
                {
                  name: 'What do Angels do?',
                  url: 'https://www.gotquestions.org/what-do-angels-do.html'
                }
              ]
            }
          },
          {
            id: 7,
            text: 'The Church',
            value: 0,
            feedback: {
              startBook: 150,
              endBook: 151,
              realStartBook: 140,
              realEndBook: 141,
              watchExtra: [
                {
                  name: 'Who is the Church?',
                  url: 'https://www.youtube.com/watch?v=gfqsoSJ-Fgk'
                },
                {
                  name: 'Why Every Christian Should Belong to a Church',
                  url: 'https://www.youtube.com/watch?v=z-KxHuMXoJE'
                }
              ],
              readExtra: [
                {
                  name: 'Theology of the Church',
                  url: 'https://www.thegospelcoalition.org/topics/theology-of-the-church/'
                },
                {
                  name: 'Is there supposed to be only one church?',
                  url: 'https://www.gotquestions.org/one-church.html'
                }
              ]
            }
          },
          {
            id: 8,
            text: 'End Times Events',
            value: 0,
            feedback: {
              startBook: 153,
              endBook: 156,
              realStartBook: 143,
              realEndBook: 146,
              watchExtra: [],
              readExtra: []
            }
          },
          {
            id: 9,
            text: 'Eternity: Heaven / Hell',
            value: 0,
            feedback: {
              startBook: 153,
              endBook: 156,
              realStartBook: 143,
              realEndBook: 146,
              watchExtra: [],
              readExtra: [
                {
                  name: 'What is Heaven like?',
                  url: 'https://www.gotquestions.org/heaven-like.html'
                },
                {
                  name: 'What you need to know about Heaven',
                  url: 'https://digitalcommons.liberty.edu/will_know/53/'
                }
              ]
            }
          }
        ]
      },
      {
        title: i18n.t('bibleSurveyTitle'),
        subTitle: i18n.t('bibleSurveyCaption'),
        min: Number(0),
        max: Number(5),
        small: true,
        messages: [
          {
            id: 0,
            text: 'Story of the Bible',
            value: 0,
            feedback: {
              youtubeId: 'dLFCE8z__hw',
              youtubeName: 'BibleProject - Plot in Biblical Narrative',
              startBook: 117,
              endBook: 123,
              realStartBook: 108,
              realEndBook: 114,
              watchExtra: [
                {
                  name: 'BibleProject - The Story of the Bible',
                  url: 'https://www.youtube.com/watch?v=7_CGP-12AE0'
                }
              ],
              readExtra: []
            }
          },
          {
            id: 1,
            text: 'Old Testament sections / themes',
            value: 0,
            feedback: {
              youtubeId: 'ALsluAKBZ-c',
              youtubeName: 'BibleProject - Overview: TaNaK / Old Testament',
              startBook: 117,
              endBook: 121,
              realStartBook: 108,
              realEndBook: 112,
              watchExtra: [],
              readExtra: [
                {
                  name: '10 Reasons the Old Testament is important for Christians',
                  url: 'https://www.thegospelcoalition.org/article/old-testament-important/'
                },
                {
                  name: 'Is Jesus in the Od Testament?',
                  url: 'https://www.gotquestions.org/Jesus-in-the-Old-Testament.html'
                }
              ]
            }
          },
          {
            id: 2,
            text: 'New Testament sections / themes',
            value: 0,
            feedback: {
              youtubeId: 'Q0BrP8bqj0c',
              youtubeName: 'BibleProject - Overview: New Testament',
              startBook: 122,
              endBook: 123,
              realStartBook: 113,
              realEndBook: 114,
              watchExtra: [],
              readExtra: [
                {
                  name: 'What is New Testament theology?',
                  url: 'https://www.gotquestions.org/New-Testament-theology.html'
                }
              ]
            }
          },
          {
            id: 3,
            text: '66 books theme statements',
            value: 0,
            feedback: {
              startBook: 117,
              endBook: 123,
              realStartBook: 108,
              realEndBook: 114,
              watchExtra: [],
              readExtra: []
            }
          }
        ]
      }
    ]

    store.dispatch('loadSurvey', parts)

    return { surveySlug, parts }
  }
}
</script>

<style scoped>
.survey-page {
  padding: 24px;
}
</style>

